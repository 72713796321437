import React from "react"
import { graphql } from "gatsby"
import Container from "../../components/common/Container"
import GridThree from "../../components/common/GridThree"
import CardPrint from "../../components/common/CardPrint"
import Layout from "../../components/layouts/Default"
import { H2 } from "../../components/typography/Headings"
import PageHeader from "../../components/common/PageHeader"
import Breadcrumb from "../../components/common/Breadcrumb"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Sonderausgaben" desc="">
      <PageHeader title="Sonderausgaben" image={data.header} />
      <Breadcrumb
        pages={[
          { name: "Verlag", to: "/verlag/" },
          { name: "Sonderausgaben", to: "/verlag/sonderausgaben/" },
        ]}
      />

      <Container className="space-y-8 md:space-y-16">
        <div>
          <div className="mb-8 md:mb-16">
            <H2 className="mb-5 font-display">Sonderausgaben</H2>
          </div>
          <GridThree>
            {data.sonder.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/sonderausgaben/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/verlag/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: { cropFocus: ATTENTION }, width: 1600)
      }
    }
    sonder: allPublikationen(
      sort: { fields: date, order: DESC }
      filter: { art: { eq: "Sonderausgaben" } }
    ) {
      nodes {
        remoteCoverImage {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: ATTENTION }
              width: 1024
            )
          }
        }
        title
        title_slug
      }
    }
  }
`
